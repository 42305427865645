import React, { useEffect } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';

function App() {
  useEffect(() => {
    if (isAndroid) {
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.firefrone.stalktime';
    } else if (isIOS) {
      window.location.href =
        'https://apps.apple.com/us/app/stalktime-followers-reports/id1615409424';
    } else {
      window.location.href = '/stalktimeapp/';
    }
  }, []);

  return <div className="App"></div>;
}

export default App;
