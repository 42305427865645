import React from 'react';
import logo from './logo.png';
import appstore from './appstore.png';
import googleplay from './googleplay.png';

export default function StalkTime() {
  const goToLink = (link) => {
    window.location.href = link;
  };

  return (
    <div>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
        }}
      >
        <img
          style={{
            height: 200,
            width: 200,
            borderRadius: '30%',
            'box-shadow': 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
          }}
          src={logo}
          alt={'StalkTime Logo'}
        />
        <h2
          style={{
            'font-family': 'Arial',
            color: 'rgba(167,74,269,0.7)',
            margin: 30,
          }}
        >
          StalkTime
        </h2>
        <img
          style={{ height: 75, width: 225, marginRight: 10, marginLeft: 10 }}
          src={googleplay}
          alt="Google Play"
          onClick={() =>
            goToLink(
              'https://play.google.com/store/apps/details?id=com.firefrone.stalktime'
            )
          }
        />
        <img
          style={{ height: 75, width: 225, marginRight: 10, marginLeft: 10 }}
          src={appstore}
          alt="App Store"
          onClick={() =>
            goToLink(
              'https://apps.apple.com/us/app/stalktime-followers-reports/id1615409424'
            )
          }
        />
      </div>
    </div>
  );
}
